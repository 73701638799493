<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Paramètres</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <h5>Utilisateur</h5>
            <User :user="user.userData" :user-record-method="recordUser" />
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import User from '@/components/User'
import { mapActions, mapMutations, mapState } from 'vuex'
import store from '@/store'
export default {
    name: 'Parameters',
    components: { User },
    computed: {
        ...mapState('login', { user: 'user' }),
    },
    methods: {
        ...mapMutations('login', ['UPDATE_USER_DATA']),
        ...mapActions('notifications', ['addNotification']),
        recordUser(userData) {
            return backendApi
                .updateSelfInfos(this.$store.state.login.user.token, userData)
                .then((data) => {
                    this.UPDATE_USER_DATA(data)
                    this.addNotification({ status: 'success', message: 'Modifications enregistrées avec succès' })
                })
                .catch(backendApi.notificationOnError)
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('login/updateUserInfos')
        next()
    },
    beforeRouteUpdate(to, from, next) {
        store.dispatch('login/updateUserInfos')
        next()
    },
}
</script>

<style scoped></style>
